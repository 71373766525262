import React from 'react'
import BaseButton, {
  ButtonProps,
} from '@superrb/gatsby-addons/src/components/button'

export enum ButtonStyle {
  primary = 'primary',
  secondary = 'secondary',
  tertiary = 'tertiary',
  quaternary = 'quaternary',
}

interface Props extends ButtonProps {
  buttonStyle?: ButtonStyle
}

const Button = ({
  buttonStyle = 'primary',
  className = '',
  label = '',
  ...props
}: Props) => (
  <BaseButton
    className={`button--${buttonStyle} ${className}`}
    label={label}
    data-label={label}
    {...props}
  ></BaseButton>
)

export default Button
