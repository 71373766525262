import React, { useCallback, useContext, useRef } from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MainHeader from '../types/main-header'
import { NavContext } from '@superrb/gatsby-addons/context'
import { SiteConfig } from '@superrb/gatsby-addons/types'
import { useHideOnScroll, useIsMobile } from '@superrb/gatsby-addons/hooks'
import {
  Link,
  MenuToggle,
  SocialIcons,
} from '@superrb/gatsby-addons/components'
import SmallLogo from './images/small-logo.svg'
import Navigation from './navigation'
import NavIcon from './images/icons/nav-icon'

const Header = () => {
  const data = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: mainHeader {
        ...MainHeader
      }
      config: siteConfig {
        ...SiteConfig
      }
    }
  `)

  const isMobile = useIsMobile(false)
  const { navOpen, closeNav } = useContext(NavContext)
  const headerElement = useRef(null)

  const hidden = useHideOnScroll(true)

  const header: MainHeader = data?.header
  if (!header) {
    return null
  }

  const config: SiteConfig = data?.config
  if (!config) {
    return null
  }

  const handleClick = useCallback(() => {
    closeNav()
    document.activeElement?.blur()
  }, [closeNav])

  return (
    <>
      <header className="main-header" ref={headerElement} aria-hidden={hidden}>
        <div className="main-header__container container">
          <Link to="/" className="main-header__logo">
            <SmallLogo />
          </Link>

          {!isMobile && <Navigation />}

          <div
            className="main-header__social"
            aria-hidden={isMobile && navOpen}
          >
            <SocialIcons />
          </div>

          {/*{isMobile && <MenuToggle aria-controls="nav" renderIcon={NavIcon} />}*/}
        </div>
      </header>

      {isMobile && <Navigation />}
    </>
  )
}

export default Header
