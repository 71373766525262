import React, { useContext, useEffect, useState } from 'react'
import { CookiesContext } from '../context/cookies-context'
import { TranslationContext } from '@superrb/gatsby-addons/context'
import Button, { ButtonStyle } from './button'
import { Link } from '@superrb/gatsby-addons/components'

const CookieBanner = () => {
  // Have we already accepted cookies?
  const { cookiesAccepted, setCookiesAccepted } = useContext(CookiesContext)

  const [hidden, setHidden] = useState<boolean>(false)
  const [showCookieBanner, setShowCookieBanner] = useState<boolean>(
    !cookiesAccepted,
  )
  const { translate } = useContext(TranslationContext)

  const close = () => {
    setHidden(true)

    window.setTimeout(() => {
      setShowCookieBanner(false)
    }, 650)
  }

  const accept = () => {
    close()

    window.setTimeout(() => {
      setCookiesAccepted(true)
    }, 650)
  }

  const reject = () => {
    close()
  }

  useEffect(() => {
    if (cookiesAccepted) {
      setShowCookieBanner(false)
    }
  }, [cookiesAccepted, setShowCookieBanner])

  return (
    <>
      {showCookieBanner && (
        <div className="cookie-banner" aria-hidden={hidden}>
          <div className="cookie-banner__container container">
            <div className="cookie-banner__message">
              <h2 className="cookie-banner__title">
                {translate('cookie_banner.title')}
              </h2>
              <span className="cookie-banner__text">
                {translate('cookie_banner.text')}{' '}
                <Link to={{ type: 'LegalPage', uid: 'privacy-policy' }}>
                  Read our privacy policy
                </Link>
                .
              </span>
            </div>

            <div className="cookie-banner__buttons">
              <Button
                onClick={reject}
                buttonStyle={ButtonStyle.tertiary}
                className="cookie-banner__reject"
                label={translate('cookie_banner.reject')}
              />
              <Button
                onClick={accept}
                buttonStyle={ButtonStyle.secondary}
                className="cookie-banner__accept"
                label={translate('cookie_banner.accept')}
              />
            </div>
          </div>
        </div>
      )}
    </>
  )
}

export default CookieBanner
