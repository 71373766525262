const getPageClasses = (pathname: string) => {
  if (pathname === '/' || pathname === '') {
    pathname = 'home'
  }

  return `page ${[
    ...pathname
      .split('/')
      .filter((x) => !!x)
      .map((slug) => `page--${slug}`),
  ].join(' ')}`
}

export default getPageClasses
