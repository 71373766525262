import React, { StrictMode } from 'react'
import { Layout } from './src/components/layout'
import { Web3ReactProvider } from '@web3-react/core'
import { Web3Provider } from '@ethersproject/providers'
import { Buffer } from 'buffer'

import './src/stylesheets/style.sass'
import { StateInspector } from 'reinspect'
import { ContextWrapper } from '@superrb/gatsby-addons/components'
import CookiesContextProvider from './src/context/cookies-context'
import getPageClasses from './src/utils/get-page-classes'

function getLibrary(provider) {
  return new Web3Provider(provider)
}

window.Buffer = window.Buffer || Buffer

export const wrapRootElement = ({ element }) => (
  <StrictMode>
    <StateInspector name="App">
      <Web3ReactProvider getLibrary={getLibrary}>
        <ContextWrapper>
          <CookiesContextProvider>
            <Layout>{element}</Layout>
          </CookiesContextProvider>
        </ContextWrapper>
      </Web3ReactProvider>
    </StateInspector>
  </StrictMode>
)

export const onRouteUpdate = ({ location: { pathname }, prevLocation }) => {
  document.body.setAttribute('class', getPageClasses(pathname))
}
