import React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import MainFooter from '../types/main-footer'
import { Link } from '@superrb/gatsby-addons/components'
import moment from 'moment'
import TwitterFollow from './images/twitter-follow.svg'
import { SiteConfig } from '@superrb/gatsby-addons-filesystem/types'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query MainFooterQuery {
      footer: mainFooter {
        ...MainFooter
      }
      config: siteConfig {
        ...SiteConfig
      }
    }
  `)

  if (!data.footer) {
    return null
  }

  const {
    data: { navigation_links, legal_links, copyright },
  }: MainFooter = data.footer

  const {
    data: { twitter_username },
  }: SiteConfig = data.config

  return (
    <footer className="footer">
      <div className="footer__follow container">
        <Link to={`${twitter_username}`}>
          <TwitterFollow />
        </Link>
      </div>
      <div className="footer__container container">
        <nav className="footer__navigation">
          <ul className="footer__navigation-links">
            {navigation_links.map(({ link, label }, index) => (
              <li className="footer__navigation-links-item" key={index}>
                <Link to={link}>{label}</Link>
              </li>
            ))}
          </ul>
        </nav>

        <div className="footer__bottom">
          {legal_links.length > 0 && (
            <nav className="footer__legal">
              <ul className="footer__legal-links">
                {legal_links.map(({ link, label }, index) => (
                  <li className="footer__legal-links-item" key={index}>
                    <Link to={link}>{label}</Link>
                  </li>
                ))}
              </ul>
            </nav>
          )}

          {copyright && (
            <span className="footer__copyright">
              {copyright.replace('{year}', moment().format('Y'))}
            </span>
          )}
        </div>
      </div>
    </footer>
  )
}

export default Footer
