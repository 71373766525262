import React, {
  PropsWithChildren,
  useCallback,
  useContext,
  useEffect,
} from 'react'
import { Helmet } from 'react-helmet'
import { SkipTo } from './skip-to'
import { BackToTop } from './back-to-top'
import Header from './header'
import Footer from './footer'
import { usePreload } from '@superrb/gatsby-addons/hooks'
import CookieBanner from './cookies'
import SuccessMessage from './form/success-message'
import { ErrorPageContext } from '@superrb/gatsby-addons/context'

export const Layout = ({ children }: PropsWithChildren<{}>) => {
  const { setIsErrorPage } = useContext(ErrorPageContext)
  setIsErrorPage(false)

  return (
    <>
      <Helmet>
        <meta charSet="utf-8" />
        <meta
          name="viewport"
          content="width=device-width, initial-scale=1.0, viewport-fit=cover"
        />
        <link rel="stylesheet" href="https://use.typekit.net/vti2ucf.css" />
      </Helmet>
      <SkipTo />
      <Header />
      <main id="content">{children}</main>
      <Footer />
      <CookieBanner />
      <BackToTop />
    </>
  )
}
