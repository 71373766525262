import { NavContext } from '@superrb/gatsby-addons/context'
import { useIsMobile } from '@superrb/gatsby-addons/hooks'
import React, { useContext } from 'react'
import MainHeader from '../types/main-header'
import { SiteConfig } from '@superrb/gatsby-addons-filesystem/types'
import { graphql, useStaticQuery } from 'gatsby'
import { Link, SocialIcons } from '@superrb/gatsby-addons/components'
import Heading from './heading'
import Underline from './underline'

const Navigation = () => {
  const { navOpen, closeNav } = useContext(NavContext)
  const isMobile = useIsMobile()

  const data = useStaticQuery(graphql`
    query MainHeaderQuery {
      header: mainHeader {
        ...MainHeader
      }
      config: siteConfig {
        ...SiteConfig
      }
    }
  `)

  const header: MainHeader = data?.header
  if (!header) {
    return null
  }

  const config: SiteConfig = data?.config
  if (!config) {
    return null
  }

  return (
    <nav className="nav" aria-hidden={isMobile && !navOpen}>
      {/*<ul className="nav__list">*/}
      {/*  {header?.data?.navigation_items?.map((item, index) => {*/}
      {/*    const { label, link } = item*/}
      {/*    return (*/}
      {/*      <li className="nav__item" key={index}>*/}
      {/*        <Link to={link} className="nav__link" onClick={closeNav}>*/}
      {/*          {label}*/}
      {/*        </Link>*/}
      {/*      </li>*/}
      {/*    )*/}
      {/*  })}*/}
      {/*</ul>*/}

      {isMobile && (
        <div className="nav__social">
          <h3 className="nav__social-title underline-wrapper">
            Join us
            <Underline style={2} />
          </h3>

          <SocialIcons className="nav__social-links" />
        </div>
      )}
    </nav>
  )
}

export default Navigation
