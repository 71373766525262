import React, { useEffect, useState } from 'react'
import styleOne from '../animations/becomeworthy-underline.json'
import styleTwo from '../animations/registered-underline.json'
import Lottie from 'react-lottie-player'
import { useIsInViewport, useMotionAllowed } from '@superrb/gatsby-addons/hooks'

const styles = [styleOne, styleTwo]

const Underline = ({
  style = 1,
  delay = 0,
  className = '',
}: {
  style?: number
  delay?: number
  className?: string
}) => {
  const animationData = styles[style - 1]
  const { isInViewport, setRef } = useIsInViewport(false)
  const isMotionAllowed = useMotionAllowed()
  const [playing, setPlaying] = useState<boolean>(false)

  useEffect(() => {
    if (isInViewport) {
      setTimeout(() => {
        setPlaying(isMotionAllowed)
      }, delay)

      return
    }

    setPlaying(false)
  })

  return (
    <div className={`underline ${className}`} ref={setRef}>
      <Lottie
        animationData={animationData}
        loop={false}
        play={playing}
        style={{ height: null }}
      />
    </div>
  )
}

export default Underline
